<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4519_5373)">
      <path
        d="M9.33337 14C11.5425 14 13.3334 11.5376 13.3334 8.5C13.3334 5.46243 11.5425 3 9.33337 3C7.12423 3 5.33337 5.46243 5.33337 8.5C5.33337 11.5376 7.12423 14 9.33337 14Z"
        stroke="#868D9C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="3.66602" cy="13.5" r="1" fill="#868D9C" />
    </g>
    <defs>
      <clipPath id="clip0_4519_5373">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
